import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { generateLicense } from '../../utils/api';
import { useAuth } from '../../context/AuthContext';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  direction: rtl;
`;

const LicenseCode = styled.div`
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 20px 0;
  font-size: 18px;
  font-weight: bold;
  word-wrap: break-word;
  white-space: pre-wrap; /* Ensures wrapping */
  max-width: 100%;
  overflow-wrap: break-word;
  text-align: center; /* Center the text inside the box */
  border-radius: 5px; /* Rounded corners for a modern look */
`;

const Button = styled.a`
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
  font-size: 16px; /* Base font size */

  /* Media query for smaller screens */
  @media (max-width: 600px) {
    padding: 15px 30px; /* Increase padding on smaller screens */
    font-size: 18px; /* Increase font size on smaller screens */
    width: 100%; /* Make button full width */
    text-align: center; /* Center the text */
  }
`;


const GRELicense = () => {
  const [license, setLicense] = useState('');
  const [error, setError] = useState('');
  const { isAuthorized, setIsAuthorized } = useAuth();

  useEffect(() => {
    if (!isAuthorized) return;

    const fetchLicense = async () => {
      try {
        const generatedLicense = await generateLicense('66d00cf0bbe05b4d94ea2e6f');
        setLicense(generatedLicense);
      } catch (error) {
        console.error('Error generating license:', error);
        setError('خطا در تولید لایسنس. لطفا دوباره تلاش کنید.');
      }
    };

    fetchLicense();
    return () => setIsAuthorized(false);
  }, [isAuthorized, setIsAuthorized]);

  if (!isAuthorized) {
    return <Navigate to="/" replace />;
  }

  return (
    <Container>
      <h1>GRE دریافت لایسنس</h1>
      {error ? (
        <p>{error}</p>
      ) : (
        <>
          <p>لایسنس شما با موفقیت ایجاد شد. لطفاً این کد را در نرم‌افزار خود وارد کنید:</p>
          <LicenseCode>{license}</LicenseCode>
          <p>برای راهنمایی در مورد نحوه فعال‌سازی لایسنس، لطفاً دستورالعمل زیر را دانلود کنید:</p>
          <Button href="https://app.spotplayer.ir/player/help/" target="_blank" rel="noopener noreferrer">
            دانلود راهنما
          </Button>
        </>
      )}
    </Container>
  );
};

export default GRELicense;
